/**
 * This is the main entry point for amprion base (subprojects have their own entrypoints)
 */
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';

/**
 * Import entry scss file for amprion
 * TODO: eventually rename the entry file and give a more appropriate name
 */
import '../../scss/style.scss';

import 'es6-promise/auto';
import 'whatwg-fetch';
import 'babel-polyfill';
import arrayFrom from 'array-from';
import TeaserGroup from './TeaserGroup';
import ScrollHandler from './ScrollHandler';
import HeaderNavigation from './HeaderNavigation';
import SidebarNavigation from './SidebarNavigation';
import DefaultAccordion from './DefaultAccordion';
import StickyText from './StickyText';
import DefaultGallery from './DefaultGallery';
import ModalLayer from './ModalLayer';
import Glossary from './Glossary';
import GridData from './GridData';
import Stage from './Stage';
import ContentTeaserSlider from './ContentTeaserSlider';
import FactsFigures from './FactsFigures';
import Search from './Search';
import SearchBar from './SearchBar';
import EmailHandler from './EmailHandler';
import JobTeaserNumber from './JobTeaserNumber';
import HeroSlider from './HeroSlider';
import VideoTeaserLightbox from './VideoTeaserLightbox';
import iFrameHandler from './iFrameHandler';
import ToTopButton from './ToTopButton';
import ConsentManager from './ConsentManager';
import ConsentVideo from './ConsentVideo';
import ConsentIFrame from './ConsentIFrame';
import ConsentMedia from './ConsentMedia';
import BackButtonHandler from './BackButtonHandler';
import FeedTeaser from './FeedTeaser';
import ConsentFeedTeaser from './ConsentFeedTeaser';
import AccessRestriction from './AccessRestriction';
import Systemvison2050PartnerTeaser from './Systemvison2050PartnerTeaser';
import ModelingFilter from './ModelingFilter';
import ProjectsSlider from './ProjectsSlider';
import PortraitSlider from './PortraitSlider';
import Systemvison2050ProjectTeaser from './Systemvison2050ProjectTeaser';
import PartnerSlider from './PartnerSlider';
import ExpertSlider from './ExpertSlider';
import SankeyChart from './SankeyChart';
import JobTeaserApi from './JobTeaserApi';
import SpotImage from './SpotImage';
import StartSiteTicker from './StartSiteTicker';
import iFrameResizer from './iFrameResizer';
import AlgoliaSearch from './AlgoliaSearch';
import AlgoliaSearchBar from './AlgoliaSearchBar';
import ContentHubFilter from './ContentHubFilter';
import ContentHubShowStories from './ContentHubShowStories';
import ContentHubArticleStickyIndex from './ContentHubArticleStickyIndex';
import ContentHubDefaultSlider from './ContentHubDefaultSlider';
import ContentHubDefaultGallery from './ContentHubDefaultGallery';
import SliderCard from './SliderCard';
import ContentHubBildErklaertextSlider from './ContenHubBildErklaertextSlider';
import ZoomImage from './ZoomImage';
import ContentHubArticleIndex from './ContentHubArticleIndex';
import ContentAccessRestriction from './ContentAccessRestriction';
// OVERRIDES
/*
  override defineClassesMaster and defineClassesAdditional in sub projects of dotCMS master
 */
const defineClassesMaster = () => ({
  TeaserGroup,
  HeaderNavigation,
  SidebarNavigation,
  ScrollHandler,
  DefaultAccordion,
  StickyText,
  DefaultGallery,
  ModalLayer,
  Glossary,
  GridData,
  Stage,
  ContentTeaserSlider,
  FactsFigures,
  Search,
  SearchBar,
  EmailHandler,
  JobTeaserNumber,
  HeroSlider,
  VideoTeaserLightbox,
  iFrameHandler,
  ToTopButton,
  ConsentManager,
  ConsentMedia,
  ConsentVideo,
  ConsentIFrame,
  BackButtonHandler,
  FeedTeaser,
  ConsentFeedTeaser,
  AccessRestriction,
  Systemvison2050PartnerTeaser,
  ModelingFilter,
  ProjectsSlider,
  PortraitSlider,
  PartnerSlider,
  ExpertSlider,
  Systemvison2050ProjectTeaser,
  SankeyChart,
  JobTeaserApi,
  SpotImage,
  StartSiteTicker,
  iFrameResizer,
  AlgoliaSearch,
  AlgoliaSearchBar,
  ContentHubFilter,
  ContentHubShowStories,
  ContentHubArticleStickyIndex,
  SliderCard,
  ContentHubDefaultSlider,
  ContentHubDefaultGallery,
  ContentHubBildErklaertextSlider,
  ZoomImage,
  ContentHubArticleIndex,
  ContentAccessRestriction,
});

// ToDo: put classes to their subproject
// ToDo: create new webpack entry points!

const defineClassesAdditional = () => (window.classesAdditional || {
});

// Global storage for optional additional classes/objects/modules
window.sharedObjects = window.sharedObjects || {};

// GLOBAL INIT
/*
  all components that have a javascript module attached must provide "data-module" attribute in markup with the name of their JS class
  then their JS module will be instantiated
 */
const getModuleForInit = () => {
  const classesMaster = defineClassesMaster();
  const classesAdditional = defineClassesAdditional();

  return Object.assign({}, classesMaster, classesAdditional);
};


const initModules = () => {
  // get all modules that will be used for auto initialization
  const classes = getModuleForInit();

  // get all modules to initialize on page
  const moduleElements = document.querySelectorAll('[data-module]');

  // initialize modules
  window.initializedModuleInstances = arrayFrom(moduleElements).map((moduleElement) => {
    const moduleClassName = moduleElement.getAttribute('data-module');
    if (moduleClassName === 'ModelingFilter' && window.MSInputMethodContext && document.documentMode) {
      // return null when IE because of error in IE
      return null;
    }

    try {
      if (moduleClassName !== '') {
        const instance = new (classes[moduleClassName])({
          element: moduleElement,
        });
        instance.init();
        return instance;
      }

      return null;
    } catch (er) {
      console.error(`error initializing module instance with class "${moduleClassName}"`);
      console.error(er);
      return null;
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initModules();
  picturefill();
  objectFitImages();
});
