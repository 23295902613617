class ContentHubArticleIndex {

  constructor(options = {}) {
    this.element = options.element;
    this.INDEX_LINKS = this.element.querySelectorAll('li a');
  }

  init() {
    this.scrollToAnchor();
  }

  scrollToAnchor() {
    this.INDEX_LINKS.forEach((anchor) => {
      anchor.addEventListener('click', function listen(event) {
        event.preventDefault();
        const targetId = CSS.escape(this.getAttribute('href').substring(1));
        const targetElement = document.querySelector(`#${targetId}`);
        const offsetPositionAdjustmentSmallHeader = 80;
        const offsetPositionAdjustmentBigHeader = 216;
        this.headerHeight = document.getElementById('header').offsetHeight;

        if (targetElement) {
          if (this.headerHeight <= 91) {
            // eslint-disable-next-line max-len
            this.targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - offsetPositionAdjustmentSmallHeader;
          } else {
            // eslint-disable-next-line max-len
            this.targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - offsetPositionAdjustmentBigHeader;
          }

          const offsetPosition = this.targetPosition;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      });
    });
  }
}

export default ContentHubArticleIndex;
