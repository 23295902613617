import arrayFrom from 'array-from';
import CustomEvent from 'custom-event';

function addClass(element, className) {
  if (!element) {
    return;
  }

  if (
    Object.prototype.toString.call(element) === '[object NodeList]' ||
    Object.prototype.toString.call(element) === '[object Array]' ||
    Object.prototype.toString.call(element) === '[object HTMLCollection]'
  ) {
    arrayFrom(element).forEach(subelement => addClass(subelement, className));
    return;
  }

  const classes = (element.getAttribute('class') || '').split(' ').filter(Boolean).filter(cn => cn !== className);
  classes.push(className);
  element.setAttribute('class', classes.join(' '));
}

function removeClass(element, className) {
  if (!element) {
    return;
  }

  if (
    Object.prototype.toString.call(element) === '[object NodeList]' ||
    Object.prototype.toString.call(element) === '[object Array]' ||
    Object.prototype.toString.call(element) === '[object HTMLCollection]'
  ) {
    arrayFrom(element).forEach(subelement => removeClass(subelement, className));
    return;
  }

  const classes = (element.getAttribute('class') || '').split(' ').filter(Boolean).filter(cn => cn !== className);
  element.setAttribute('class', classes.join(' '));
}

function hasClass(element, className) {
  if (!element) {
    return false;
  }

  return (element.getAttribute('class') || '').split(' ').some(cn => cn === className);
}

function toggleClass(element, className) {
  if (!element) {
    return;
  }

  if (hasClass(element, className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}

function getParents(node) {
  const nodeParents = [];
  let iterateNode = node;
  while (iterateNode && iterateNode !== document) {
    nodeParents.push(iterateNode);
    iterateNode = iterateNode.parentNode || iterateNode.parentElement;
  }

  return nodeParents;
}

function isNumber(number) {
  return !isNaN(number - 0) && number !== null && number !== '' && number !== false;
}

function findParent(element, selector) {
  let { parentElement } = element;

  if (parentElement !== null) {
    if (!parentElement.classList.contains(selector)) {
      parentElement = findParent(parentElement, selector);
    }
  }

  return parentElement;
}

function getWindowSize() {
  /* === grab the correct inner size without scrollbar - var innerWidth = zo.innerSize().width; === */
  let v = window;
  let a = 'inner';

  if (!('innerWidth' in v)) {
    a = 'client';
    v = document.documentElement || document.body;
  }

  return {
    width: v[`${a}Width`],
    height: v[`${a}Height`],
  };
}

function dispatchCustomEvent(eventType, payload) {
  const customEvent = new CustomEvent(
    eventType,
    { detail: payload },
  );

  // dispatch event for project
  window.dispatchEvent(customEvent);
}

function wrapElement(element, wrapNodeName, wrapClass = '') {
  const wrapperNode = document.createElement(wrapNodeName);
  if (wrapClass) {
    addClass(wrapperNode, wrapClass);
  }

  element.parentNode.insertBefore(wrapperNode, element);
  wrapperNode.appendChild(element);
  return wrapperNode;
}

function generateSVGIcon(iconName, className = null, focusAble = null, style = null) {
  let classContent = '';
  if (className) {
    classContent = ` class="${className}"`;
  }

  let focusContent = '';
  if (focusAble) {
    focusContent = `focusable=${focusAble}`;
  }

  let styleContent = '';
  if (style) {
    styleContent = `style=${style}`;
  }

  return `<svg${classContent} ${focusContent} ${styleContent}><use xlink:href="#svgsymbol-${iconName}" /></svg>`;
}

/**
 *
 * @param content
 * @param placeholder
 * @param value
 * @returns {*}
 */
function replacePlaceholder(content, placeholder, value) {
  if (value || value === '') {
    return content.replace(`~${placeholder}~`, value);
  }

  return content;
}

function getViewportWidth(viewports, viewportKey) {
  const viewportForKey = viewports.filter(value => value.name === viewportKey);
  if (viewportForKey.length === 1) {
    return viewportForKey[0].width;
  }

  return 0;
}

function hexToRgb(hexNumber, alphaChannel) {
  let rgba = '';
  const alpha = alphaChannel;
  let hex = hexNumber;
  hex = hex.replace('#', '');
  const r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
  const g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
  const b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  if (alpha) {
    rgba = `rgba(${r},${g},${b},${alpha})`;
  } else {
    rgba = `rgba(${r},${g},${b})`;
  }

  return rgba;
}

async function loadJsonFileIntoSessionStorage(jsonFile, sessionStorageKey) {
  // load json data from URL and add it to the session storage
  await fetch(jsonFile)
    .then(response => response.json())
    .then((data) => {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
    })
    .catch((error) => {
      console.error('Error fetching the JSON:', error);
    });
}

export {
  addClass,
  hasClass,
  isNumber,
  findParent,
  getParents,
  removeClass,
  toggleClass,
  getWindowSize,
  dispatchCustomEvent,
  wrapElement,
  generateSVGIcon,
  replacePlaceholder,
  getViewportWidth,
  hexToRgb,
  loadJsonFileIntoSessionStorage,
};
